(function ($) {
  // When the menu changes, update the quantity on the add-to-cart buttons
  $(document).on('change', 'select.js-quantity-menu', function () {
    var $self = $(this);
    var $prod = $self.closest('.js-product');
    var qty = parseInt($self.val());

    $prod.find('.js-add-to-cart, js-add-to-bag').data('qty', qty);
    if (Drupal.settings.common.clearpay_unit_price) {
      Drupal.behaviors.productInstallmentPriceUKV1.initClearPay();
    }
    if (Drupal.settings.afterpay_price) {
      Drupal.behaviors.afterPayInit.initAfterPay();
    }
  });

  document.querySelectorAll('.js-quantity--menu__container').forEach((container) => {
    const $container = $(container);
    const $prod = $container.closest('.js-product');
    const input = container.querySelector('.js-quantity--input');
    const minusBtn = container.querySelector('.js-minus-btn');
    const plusBtn = container.querySelector('.js-plus-btn');

    function updateProductQuantity($product, qty) {
      var $cta = $product.find('.js-add-to-cart, .js-add-to-bag');
      var skuBaseId = $cta.attr('data-selected-sku');
      var replenishment = $cta.attr('data-replenishment') || 0;

      $cta.attr('data-qty', qty);

      if (parseInt(replenishment) === 0) {
        // Amazon: Buy With Prime
        $(document).trigger('amazon:bwp_widget:update', [skuBaseId, qty]);
      }

      if (Drupal.settings.common.clearpay_unit_price) {
        Drupal.behaviors.productInstallmentPriceUKV1.initClearPay();
      }
      if (Drupal.settings.afterpay_price) {
        Drupal.behaviors.afterPayInit.initAfterPay();
      }
    }

    $(minusBtn).on('click', function () {
      const $input = $(input);
      const currentValue = parseInt($input.val(), 10);

      if (currentValue > 1) {
        $input.val(currentValue - 1).trigger('change');
        updateProductQuantity($prod, currentValue - 1);
      }
    });

    $(plusBtn).on('click', function () {
      const $input = $(input);
      const newValue = parseInt($input.val(), 10) + 1;

      $input.val(newValue).trigger('change');
      updateProductQuantity($prod, newValue);
    });

    $(input).on('input change', function () {
      const $input = $(this);
      let value = parseInt($input.val(), 10) || 1;

      if (value < 1) {
        value = 1;
        $input.val(1);
      }

      updateProductQuantity($prod, value);
    });
  });
})(jQuery);
